import React from "react";

const Contact = () => {
    return (
        <div className="App-contact">
            <p className="contact">hi@satalaia.co</p>
        </div>
    );
};

export default Contact;
